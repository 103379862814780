.profile-dropdown {
  position: absolute;
  right: 1rem;
  list-style-type: none;
  /* top: 4rem; */
}

.dgv-logo {
  max-width: 120px;
  max-height: 120px;
  padding: .5rem;
}

.hidden {
  display: none;
}

.nav-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-right: 1rem;
  border-bottom: 1px solid black;
  background-color: rgb(49, 49, 49);
  /* padding: 1rem; */
}

.nav-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  margin-right: 1rem;
}

.nav-item {
  margin: 0 .3rem 0 .3rem;
}

.nav-item a {
  text-decoration: none;
  color: #fff;
}

.nav-item a:visited{
  text-decoration: none;
  color: #fff;
}

.user-option {
  border: 1px solid transparent;
  padding: 10px
}

.user-option:hover {
  border: 1px solid #fff;
}