.user-review-form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    background-color: #313131;
    border-radius: 20px;
    color: #fff;
}

.user-disc-review,
.user-disc-rating {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.submit-review-btn {
    border: none;
    border-radius: 10px;
    padding: 8px;
    background-color: #0c0c0c;
    color: #fff;
    cursor: pointer;
}

.submit-review-btn:hover {
    background-color: #008000;
    color: white
}
