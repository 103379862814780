.review-tile {
    background-color: #313131;
    color: #fff;
    max-width: 200px;
    border-radius: 10px;
    padding: 3px;
    border: 1px solid #fff;
}

.review-user,
.user-review {
    margin-left: 1rem;
    margin-left: 1rem;
    word-break: break-word;
    overflow-wrap: break-word;
    /* overflow: hidden; */
}

.delete-review-btn {
    margin: 0 1rem .7rem 1rem;
    border: 1px solid transparent;
    background-color: #bdbdbd;
    border-radius: 5px;
    width: 3rem;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
    margin-left: 0 auto;
}

.delete-review-btn:hover {
    color: #fff;
    background-color: #ff0000;
}