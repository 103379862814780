.log-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #313131;
    border-radius: 20px;
    color: #fff;
    padding: 2rem;
}

.log-in-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.log-in-form h1 {
    margin: 0;
}

.log-in-content {
    display: flex;
    flex-direction: column;
    
}

.log-in-btn-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.log-in-btn,
.log-in-demo-btn {
    border: none;
    border-radius: 10px;
    padding: 8px;
    background-color: #0c0c0c;
    color: #fff;
    cursor: pointer;
}

.log-in-btn:hover {
    background-color: #008000;
    color: #fff
}

.log-in-demo-btn:hover {
    background-color: #0000ff;
    color: #fff
}