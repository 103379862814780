
.flight-data-container {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    padding: .5rem;
    color: #fff;
    background-color: #313131;
    border-radius: 20px;
}

.flight-name {
    font-size: 1.5rem;
    font-weight: 600;
}

.name-and-nums {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8rem;
}

.flight-nums {
    border: 1px solid #fff;
    padding: .4rem;
}

.flight-sum {
    line-height: 1.4;
    padding: .5rem;
    font-size: 1.1rem;
}

#modal-content {
    border-radius: 20px;
    border: 1px solid #fff;
}

.clarify {
    font-size: .7rem;
    align-self: center;
}