.landing-page-container {
    text-align: center;
}

.disc-index{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.index-container {
    margin-top: 1rem;
    padding: 1rem;
}

@media (max-width: 1200px) {
    .disc-index {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1000px) {
    .disc-index {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .disc-index {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .disc-index {
        grid-template-columns: 1fr;
    }
}