.user-discs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.discs-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}