.details-container {
    margin: 4rem 2rem 2rem 2rem;
}

.img-info-container {
    display: flex;
    justify-content: center;
    /* flex-wrap: wrap; */
}

.img-container {
    flex: 1;
    margin-right: 1rem;
    max-width: 600px;
    min-width: 300px;
}

.disc-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    min-width: 300px;
    background-color: #313131;
    color: #fff;
    padding: 1rem;
    border: 1px solid #fff;
    box-shadow: -1px 4px 5px 0px #000000bf;
    border-radius: 10px;
    margin-left: 1rem;
}

.disc-details-img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: fill;
    border: 1px solid #000;
    box-shadow: -1px 4px 5px 0px #000000bf;
    border-radius: 10px;
}

.disc-stats-container {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    margin-right: 2rem;
    justify-content: center;
}

.disc-info h1 {
    font-size: 4rem;
    align-self: center;
    margin: 0;
}

.disc-details-description {
    font-size: 2rem;
    line-height: 1.5;
}

.disc-details-owner {
    align-self: center;
}

.disc-stat {
    border: 1px solid white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5rem;
    height: auto;
    background-color: #313131;
    color: #fff;
    box-shadow: -1px 4px 5px 0px #000000bf;
    border-radius: 10px;
    cursor: pointer;
}

.disc-stat:hover {
    color: #f801f8;
    border: 1px solid #f801f8;
}

.disc-stat p {
    margin: 0 0 .2rem 0;
}

.disc-stat div {
    margin-top: .2rem;
    font-size: 2.5rem;
}

.disc-reviews-header {
    margin-left: .2rem;
}

.user-reviews-divider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
}

.add-review-btn {
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #313131;
    color: #fff;
    cursor: pointer;
}

.add-review-btn:hover{
    color: #f801f8;
    border: 1px solid #f801f8;
}

.first-review-btn:hover {
    color: #f801f8;
    border: 1px solid #f801f8;
}

.disc-reviews-container {
    display: grid;
    gap: 1rem;
    /* max-width: 200px; */
}

.first-review-btn {
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #313131;
    color: #fff;
    cursor: pointer;
    width: 15%;
}