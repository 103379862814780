.disc-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    min-width: 250px;
    overflow: hidden;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; 
}

.tile-wrapper {
    background-color: #313131;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.disc-details-link {
    text-decoration: none;
    max-width: 250px;
}

.disc-details {
   text-align: center;
   background-color: #313131;
   width: 100%;
   margin-top: 0;
   padding: 0;
}

.disc-details p {
    margin-bottom: 0;
    margin-top: .3rem;
}

.disc-name {
    font-size: 1.8rem;
}

.disc-stats {
    margin-top: 1rem;
    padding: 1rem;
}

.disc-category {
    font-size: 1.1rem;
}

.disc-img-container {
    width: 100%;
    position: relative;
}

.disc-img-container::before {
    content: '';
    display: block;
    padding-top: 75%;
}

.disc-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.disc-stats {
    display: flex;
    justify-content: space-around;
}

.manage-btns {
    background-color: #313131;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; 
}

.update,
.delete {
    margin: 0 1rem .7rem 1rem;
    border: 1px solid transparent;
    background-color: #bdbdbd;
    border-radius: 5px;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
}

.update:hover {
    border: 1px solid #008000;
    background-color: #008000;
    color: #fff;
}

.delete:hover {
    border: 1px solid #ff0000;
    background-color: #ff0000;
    color: #fff;
}