.profile-dropdown {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    border: 1px solid #fff;
    border-radius: 20px;
    background-color: #313131;
    color: #fff;
    padding: 20px;
}

.user-controls-main-btn {
    background-color: #bdbdbd;
    margin-right: 1rem;
    padding: 10px;
    border: none;
    border-radius: 10px;
    border: 1px solid transparent;
    cursor: pointer;
}

#user-actions-btn {
    background-color: #bdbdbd;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
}

.user-controls-main-btn:hover {
    border: 1px solid #fff;
}