@import url(https://fonts.googleapis.com/css2?family=Antonio:wght@500&family=League+Spartan:wght@400;700&display=swap);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
}


.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #313131;
    border-radius: 20px;
    color: #fff;
    padding: .8rem;
}

.delete-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#confirm-header {
    font-size: 1.6rem;
    margin: 0;
}

#you-sure {
    font-size: 1.2rem;
}

#yes,
#no {
    margin: 0 1rem .7rem 1rem;
    border: 1px solid transparent;
    background-color: #bdbdbd;
    border-radius: 5px;
    width: 3rem;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
}

#yes:hover {
    border: 1px solid #ff0000;
    background-color: #ff0000;
    color: #fff;
}

#no:hover {
    border: 1px solid #0000ff;
    background-color: #0000ff;
    color: #fff;
}
.disc-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    min-width: 250px;
    overflow: hidden;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; 
}

.tile-wrapper {
    background-color: #313131;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.disc-details-link {
    text-decoration: none;
    max-width: 250px;
}

.disc-details {
   text-align: center;
   background-color: #313131;
   width: 100%;
   margin-top: 0;
   padding: 0;
}

.disc-details p {
    margin-bottom: 0;
    margin-top: .3rem;
}

.disc-name {
    font-size: 1.8rem;
}

.disc-stats {
    margin-top: 1rem;
    padding: 1rem;
}

.disc-category {
    font-size: 1.1rem;
}

.disc-img-container {
    width: 100%;
    position: relative;
}

.disc-img-container::before {
    content: '';
    display: block;
    padding-top: 75%;
}

.disc-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.disc-stats {
    display: flex;
    justify-content: space-around;
}

.manage-btns {
    background-color: #313131;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; 
}

.update,
.delete {
    margin: 0 1rem .7rem 1rem;
    border: 1px solid transparent;
    background-color: #bdbdbd;
    border-radius: 5px;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
}

.update:hover {
    border: 1px solid #008000;
    background-color: #008000;
    color: #fff;
}

.delete:hover {
    border: 1px solid #ff0000;
    background-color: #ff0000;
    color: #fff;
}

.landing-page-container {
    text-align: center;
}

.disc-index{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
}

.index-container {
    margin-top: 1rem;
    padding: 1rem;
}

@media (max-width: 1200px) {
    .disc-index {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1000px) {
    .disc-index {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .disc-index {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .disc-index {
        grid-template-columns: 1fr;
    }
}
.form-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-disc-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    max-width: 600px;
    width: 90%;
    background-color: #313131;
    box-shadow: -1px 4px 5px 0px #000000bf;    
    color: #fff;
}

.form-box {
    margin: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%; 
}

.form-box input,
.form-box select,
.form-box textarea {
    border-radius: 5px;
    line-height: 1.5;
    border: none;
    width: 40%;
}

.form-box label {
    margin-bottom: .5rem;
}

.flight-data-container {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    padding: .5rem;
    color: #fff;
    background-color: #313131;
    border-radius: 20px;
}

.flight-name {
    font-size: 1.5rem;
    font-weight: 600;
}

.name-and-nums {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: .8rem;
    gap: .8rem;
}

.flight-nums {
    border: 1px solid #fff;
    padding: .4rem;
}

.flight-sum {
    line-height: 1.4;
    padding: .5rem;
    font-size: 1.1rem;
}

#modal-content {
    border-radius: 20px;
    border: 1px solid #fff;
}

.clarify {
    font-size: .7rem;
    align-self: center;
}
.delete-review-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #313131;
    border-radius: 20px;
    color: #fff;
    padding: .8rem;
}

.delete-review-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#confirm-delete-header {
    font-size: 1.6rem;
    margin: 0;
}

#are-you-sure {
    font-size: 1.2rem;
}

#yes-delete,
#no-keep {
    margin: 0 1rem .7rem 1rem;
    border: 1px solid transparent;
    background-color: #bdbdbd;
    border-radius: 5px;
    width: 3rem;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
}

#yes-delete:hover {
    border: 1px solid #ff0000;
    background-color: #ff0000;
    color: #fff;
}

#no-keep:hover {
    border: 1px solid #0000ff;
    background-color: #0000ff;
    color: #fff;
}
.review-tile {
    background-color: #313131;
    color: #fff;
    max-width: 200px;
    border-radius: 10px;
    padding: 3px;
    border: 1px solid #fff;
}

.review-user,
.user-review {
    margin-left: 1rem;
    margin-left: 1rem;
    word-break: break-word;
    overflow-wrap: break-word;
    /* overflow: hidden; */
}

.delete-review-btn {
    margin: 0 1rem .7rem 1rem;
    border: 1px solid transparent;
    background-color: #bdbdbd;
    border-radius: 5px;
    width: 3rem;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
    margin-left: 0 auto;
}

.delete-review-btn:hover {
    color: #fff;
    background-color: #ff0000;
}
.user-review-form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    background-color: #313131;
    border-radius: 20px;
    color: #fff;
}

.user-disc-review,
.user-disc-rating {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.submit-review-btn {
    border: none;
    border-radius: 10px;
    padding: 8px;
    background-color: #0c0c0c;
    color: #fff;
    cursor: pointer;
}

.submit-review-btn:hover {
    background-color: #008000;
    color: white
}

.details-container {
    margin: 4rem 2rem 2rem 2rem;
}

.img-info-container {
    display: flex;
    justify-content: center;
    /* flex-wrap: wrap; */
}

.img-container {
    flex: 1 1;
    margin-right: 1rem;
    max-width: 600px;
    min-width: 300px;
}

.disc-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    min-width: 300px;
    background-color: #313131;
    color: #fff;
    padding: 1rem;
    border: 1px solid #fff;
    box-shadow: -1px 4px 5px 0px #000000bf;
    border-radius: 10px;
    margin-left: 1rem;
}

.disc-details-img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: fill;
    border: 1px solid #000;
    box-shadow: -1px 4px 5px 0px #000000bf;
    border-radius: 10px;
}

.disc-stats-container {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2rem;
    margin-right: 2rem;
    justify-content: center;
}

.disc-info h1 {
    font-size: 4rem;
    align-self: center;
    margin: 0;
}

.disc-details-description {
    font-size: 2rem;
    line-height: 1.5;
}

.disc-details-owner {
    align-self: center;
}

.disc-stat {
    border: 1px solid white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5rem;
    height: auto;
    background-color: #313131;
    color: #fff;
    box-shadow: -1px 4px 5px 0px #000000bf;
    border-radius: 10px;
    cursor: pointer;
}

.disc-stat:hover {
    color: #f801f8;
    border: 1px solid #f801f8;
}

.disc-stat p {
    margin: 0 0 .2rem 0;
}

.disc-stat div {
    margin-top: .2rem;
    font-size: 2.5rem;
}

.disc-reviews-header {
    margin-left: .2rem;
}

.user-reviews-divider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
}

.add-review-btn {
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #313131;
    color: #fff;
    cursor: pointer;
}

.add-review-btn:hover{
    color: #f801f8;
    border: 1px solid #f801f8;
}

.first-review-btn:hover {
    color: #f801f8;
    border: 1px solid #f801f8;
}

.disc-reviews-container {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    /* max-width: 200px; */
}

.first-review-btn {
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #313131;
    color: #fff;
    cursor: pointer;
    width: 15%;
}

.user-discs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.discs-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
}
#second-user-actions-btn {
    background-color: #bdbdbd;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
    border: 1px solid transparent;
}

#second-user-actions-btn:hover {
    border: 1px solid #fff;
}
.log-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #313131;
    border-radius: 20px;
    color: #fff;
    padding: 2rem;
}

.log-in-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.log-in-form h1 {
    margin: 0;
}

.log-in-content {
    display: flex;
    flex-direction: column;
    
}

.log-in-btn-box {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
}

.log-in-btn,
.log-in-demo-btn {
    border: none;
    border-radius: 10px;
    padding: 8px;
    background-color: #0c0c0c;
    color: #fff;
    cursor: pointer;
}

.log-in-btn:hover {
    background-color: #008000;
    color: #fff
}

.log-in-demo-btn:hover {
    background-color: #0000ff;
    color: #fff
}
.sign-up-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #313131;
    border-radius: 20px;
    color: #fff;
    padding: 2rem;
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.sign-up-form h1 {
    margin: 0;
}

.sign-up-btn {
    border: none;
    border-radius: 10px;
    padding: 8px;
    background-color: #0c0c0c;
    color: #fff;
    cursor: pointer;
    border: 1px solid transparent;
}

.sign-up-btn:hover {
    border: 1px solid #fff;
}
.profile-dropdown {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
    border: 1px solid #fff;
    border-radius: 20px;
    background-color: #313131;
    color: #fff;
    padding: 20px;
}

.user-controls-main-btn {
    background-color: #bdbdbd;
    margin-right: 1rem;
    padding: 10px;
    border: none;
    border-radius: 10px;
    border: 1px solid transparent;
    cursor: pointer;
}

#user-actions-btn {
    background-color: #bdbdbd;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
}

.user-controls-main-btn:hover {
    border: 1px solid #fff;
}
.profile-dropdown {
  position: absolute;
  right: 1rem;
  list-style-type: none;
  /* top: 4rem; */
}

.dgv-logo {
  max-width: 120px;
  max-height: 120px;
  padding: .5rem;
}

.hidden {
  display: none;
}

.nav-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-right: 1rem;
  border-bottom: 1px solid black;
  background-color: rgb(49, 49, 49);
  /* padding: 1rem; */
}

.nav-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  margin-right: 1rem;
}

.nav-item {
  margin: 0 .3rem 0 .3rem;
}

.nav-item a {
  text-decoration: none;
  color: #fff;
}

.nav-item a:visited{
  text-decoration: none;
  color: #fff;
}

.user-option {
  border: 1px solid transparent;
  padding: 10px
}

.user-option:hover {
  border: 1px solid #fff;
}
html, body {
 padding: 0;
 margin: 0;
 box-sizing: border-box;
 background-color: rgb(189, 189, 189);
 font-family: 'League Spartan', sans-serif;
 font-weight: 400;
}

h1, h2 {
    font-family: 'Antonio', sans-serif;
}

h3, h4 {
    font-family: 'League Spartan', sans-serif;
}

.form-errors {
    color: red;
}

textarea {
    resize: none;
}
