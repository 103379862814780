#second-user-actions-btn {
    background-color: #bdbdbd;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-weight: 550;
    cursor: pointer;
    border: 1px solid transparent;
}

#second-user-actions-btn:hover {
    border: 1px solid #fff;
}