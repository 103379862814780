@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@500&family=League+Spartan:wght@400;700&display=swap');


html, body {
 padding: 0;
 margin: 0;
 box-sizing: border-box;
 background-color: rgb(189, 189, 189);
 font-family: 'League Spartan', sans-serif;
 font-weight: 400;
}

h1, h2 {
    font-family: 'Antonio', sans-serif;
}

h3, h4 {
    font-family: 'League Spartan', sans-serif;
}

.form-errors {
    color: red;
}

textarea {
    resize: none;
}