.sign-up-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #313131;
    border-radius: 20px;
    color: #fff;
    padding: 2rem;
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    gap: 1rem;
}

.sign-up-form h1 {
    margin: 0;
}

.sign-up-btn {
    border: none;
    border-radius: 10px;
    padding: 8px;
    background-color: #0c0c0c;
    color: #fff;
    cursor: pointer;
    border: 1px solid transparent;
}

.sign-up-btn:hover {
    border: 1px solid #fff;
}